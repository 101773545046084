import ENV_CONFIG from "../../config/ENV_CONFIG";

//Content API
export const CONTENT_BASEURL_V6 = `${ENV_CONFIG.API_URL}/content/v6/`;
export const CONTENT_BASEURL_V7 = `${ENV_CONFIG.API_URL}/content/v7/`;
export const CONTENT_BASEURL_V8 = `${ENV_CONFIG.API_URL}/content/v8/`;
export const CONTENT_BASEURL_V9 = `${ENV_CONFIG.API_URL}/content/v9/`;

//User API
export const USER_BASEURL_V6 = `${ENV_CONFIG.API_URL}/user/v6/`;
export const USER_BASEURL_V7 = `${ENV_CONFIG.API_URL}/user/v7/`;
export const USER_BASEURL_V8 = `${ENV_CONFIG.API_URL}/user/v8/`;
export const USER_BASEURL_V9 = `${ENV_CONFIG.API_URL}/user/v9/`;
export const USER_BASEURL_V10 = `${ENV_CONFIG.API_URL}/user/v10/`;

//Payment API
export const PAYMENT_LOCAL_V6 = "https://localhost:5001/payments/v6/";
export const PAYMENT_LOCAL_V8 = "https://localhost:5001/payments/v8/";
export const PAYMENT_BASEURL_V6 = `${ENV_CONFIG.API_URL}/payments/v6/`;
export const PAYMENT_BASEURL_V7 = `${ENV_CONFIG.API_URL}/payments/v7/`;
export const PAYMENT_BASEURL_V8 = `${ENV_CONFIG.API_URL}/payments/v8/`;