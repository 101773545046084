import ProductRedemption from "../models/ProductRedemption";
import { PAYMENT_BASEURL_V7, PAYMENT_BASEURL_V8, PAYMENT_LOCAL_V8, USER_BASEURL_V6, USER_BASEURL_V7, USER_BASEURL_V8 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";
import { FlexType } from "../enums/FlexType";
import { PaymentGateway } from "../enums/PaymentGateway";
import { PurchaseProductType } from "../enums/PurchaseProductType";
import { ProductTierDetails } from "../models/ProductTierDetails";
import { AddTransactionEventRequest } from "../models/request/AddTransactionEventRequest";
import { CaptureContextRequest } from "../models/request/CaptureContextRequest";
import { ConfirmRequest } from "../models/request/ConfirmRequest";
import { PreverifyRequest } from "../models/request/PreverifyRequest";
import { CaptureContextResponse } from "../models/response/CaptureContextResponse";
import { PreverifyResponse } from "../models/response/PreverifyResponse";
import { ResponseBase } from "../models/response/ResponseBase";
import { VerifyRequest } from "../models/request/VerifyRequest";

/**
 * Purchasing products
 * @param parentProductId - number: parentProductId
 * @returns
 */
export const redeemProduct = async (parentProductId: any) => {
	const redemptionRequest: ProductRedemption = {
		parentProductIds: parentProductId + "-6",
		forcedId: 0,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "ProductRedemption", { method: "POST", headers: getHeaders(true), body: JSON.stringify(redemptionRequest) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Purchasing products
 * @param parentProductId - number: parentProductId
 * @returns
 */
export const redeemProductV7 = async (parentProductId: any, definition: any) => {
	const redemptionRequest: any = {
		parentProductId,
		definition,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V7 + "ProductRedemption", { method: "POST", headers: getHeaders(true), body: JSON.stringify(redemptionRequest) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Purchasing products
 * @param parentProductId - number: parentProductId
 * @returns
 */
export const redeemProductV8 = async (parentProductId: any, definition: any) => {
	const redemptionRequest: any = {
		parentProductId,
		definition,
	};

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V8 + "ProductRedemption", { method: "POST", headers: getHeaders(true), body: JSON.stringify(redemptionRequest) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Check if the consumer can purchase the item.
 * @param itemId - parent product id
 * @param paymentGateway - payment gateway used
 * @param purchaseProductType - purchase product type
 * @param product - contain product details
 * @returns PreverifyResponse
 */
export const preverify = async (
	itemId: number,
	paymentGateway: PaymentGateway,
	purchaseProductType: PurchaseProductType,
	product: ProductTierDetails): Promise<PreverifyResponse> => {

	const productPreverifyRequest: PreverifyRequest = {
		paymentGateway: paymentGateway,
		purchaseProductType: purchaseProductType,
		itemId: itemId,
		productSku: product.sku,
		transactionTypeId: product.transactionTypeId,
		videoType: product.videoType,
	};

	let promise = new Promise<PreverifyResponse>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "purchase/preverify", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(productPreverifyRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Verify the payment exists on the payment gateway.
 * @param transactionId - transactionId of the transaction
 * @param itemId - parent product id
 * @param paymentGateway - payment gateway used
 * @param purchaseProductType - purchase product type
 * @param product - contain product details
 * @returns ResponseBase
 */
export const verify = async (
	transactionId: string,
	itemId: number,
	paymentGateway: PaymentGateway,
	purchaseProductType: PurchaseProductType,
	product: ProductTierDetails): Promise<ResponseBase> => {

	const request: VerifyRequest = {
		paymentGateway: paymentGateway,
		purchaseProductType: purchaseProductType,
		itemId: itemId,
		productSku: product?.sku,
		transactionTypeId: product?.transactionTypeId,
		videoType: product?.videoType,
	};

	let promise = new Promise<ResponseBase>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + `purchase/${transactionId}/verify`, {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(request),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Confirm the payment to grant the entitlement.
 * @param transactionId - transactionId of the transaction
 * @param paymentGateway - payment gateway used
 * @param purchaseProductType - purchase product type
 * @param product - contain product details
 * @returns ResponseBase
 */
export const confirm = async (
	transactionId: string,
	paymentGateway: PaymentGateway,
	purchaseProductType: PurchaseProductType,
	product: ProductTierDetails): Promise<ResponseBase> => {

	const request: ConfirmRequest = {
		paymentGateway: paymentGateway,
		purchaseProductType: purchaseProductType,
		transactionTypeId: product.transactionTypeId,
		videoType: product.videoType,
	};

	let promise = new Promise<ResponseBase>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + `purchase/${transactionId}/confirm`, {
			method: "PATCH",
			headers: getHeaders(true),
			body: JSON.stringify(request),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Generate capture context for flex micro from integration. This uses v2.
 * @param transactionId - transactionId of the transaction
 * @returns CaptureContextResponse
 */
export const generateCaptureContext = async (transactionId: string): Promise<CaptureContextResponse> => {
	const contextRequest: CaptureContextRequest = {
		transactionId,
		flexType: FlexType.FlexMicroForm,
	};

	let promise = new Promise<CaptureContextResponse>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "cybersource/context", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(contextRequest),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Generate capture context for flex micro from integration. This uses v2.
 * @param transactionId - transactionId of the transaction
 * @returns
 */
export const generateCaptureContextLocal = async (transactionId: string): Promise<CaptureContextResponse> => {
	const contextRequest: CaptureContextRequest = {
		transactionId,
		flexType: FlexType.FlexMicroForm,
	};

	let promise = new Promise<CaptureContextResponse>((resolve, reject) => {
		fetchWrapper(PAYMENT_LOCAL_V8 + "cybersource/context", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(contextRequest),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

export const addTransactionEvent = async (transactionId: string, eventType: string, message: string | null = null): Promise<ResponseBase> => {
	const request: AddTransactionEventRequest = {
		message: message
	}

	let promise = new Promise<ResponseBase>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V7 + `purchase/${transactionId}/event/${eventType}`, {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(request)	
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
}
