import { Modal as ModalPaper, Portal, Provider } from "react-native-paper";
import { TouchableOpacity } from "react-native";
import H3 from "../Typography/H3";
import H5 from "../Typography/H5";
import H4 from "../Typography/H4";
import Image from "../Image/Image";
import styled, { css } from "styled-components/native";
import TouchableButton from "../TouchableButton";
import closeIcon from "../../assets/Icons/close_icon.png";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { peacock_blue, spacing_m, spacing_xl, white_smoke } from "../../StyleHelpers";
import H6 from "../Typography/H6";
import parse from "html-react-parser";
import Spinner from "../Spinner";

interface CustomDialogProps {
	Header?: any;
	Body?: any;
	Footer?: any;
	ButtonTextLeft?: string;
	legalTextTitle?: string;
	legalButtonTextTitle?: string;
	ButtonTextMiddle?: string;
	ButtonTextRight?: string;
	ButtonSubTextLeft?: string;
	ButtonSubTextMiddle?: string;
	ButtonSubTextRight?: string;
	hasSubTextLeft?: boolean;
	hasSubTextMiddle?: boolean;
	hasSubTextRight?: boolean;
	TypeButtonColorLeft?: ComponentTypeEnum;
	TypeButtonColorMiddle?: ComponentTypeEnum;
	TypeButtonColorRight?: ComponentTypeEnum;
	BottomTextLeft?: string;
	BottomTextRight?: string;
	CloseButton?: boolean;
	SingleButton?: boolean;
	HasMiddleButton?: boolean;
	HasFirstButton?: boolean;
	isTDP?: boolean;
	isLegalTerm?: boolean;
	Width?: any;
	Height?: any;
	HideButton?: boolean;
	isProcessing?: boolean;
	onDismiss?: () => void;
	onPressRight?: () => void;
	onPressMiddle?: () => void;
	onPressLeft?: () => void;
	onPressTermsAndService?: () => void;
}

const StyledModal = styled(ModalPaper)`
	background-color: rgba(0, 0, 0, 0.9);
	align-items: center;
	position: fixed;
	z-index: 1001;
`;

const StyledInnerContent = styled.View`
	justify-content: center;
	align-items: center;
	padding: 10px ${spacing_xl} 0px ${spacing_xl};
`;

const StyledCloseContent = styled.View`
	align-items: end;
	margin-top: 10px;
	margin-right: 10px;
`;

const StyledTextContainer = styled.View`
	text-align: left;
	opacity: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const StyledMargin = styled.Text`
	margin-bottom: 30px;
`;

const StyledBottomContainer = styled.View`
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
	margin: 5px 0px 20px 0px;
`;

const StyledBottomInnerContainer = styled.View`
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
`;

const StyledBottomText = styled.Text`
	text-align: center;
	flex: 1;
	color: white;
`;

const StyledText = styled.Text<any>`
	text-align: center;
	font-size: 25px;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	${(props) =>
		css`
			color: ${props.fontColor};
		`}
`;

const StyledSubText = styled.Text`
	color: white;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: ${spacing_m};
`;

const StyledTextLoadingContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const StyledSpinner = styled.View`
	margin-bottom: 12;
`;

const StyledLegal = styled.View`
	justify-content: center;
	align-items: center;
	background-color: ${peacock_blue};
	width: 100%;
	height: 94px;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-top: 10px;
`;

const StyledLegalText = styled.Text<any>`
	color: ${white_smoke};
	font-size: 1.2rem;
	text-decoration-line: ${(props) => (props.underline ? "underline" : "none")};
`;

const CustomizeDialogBox = (props: CustomDialogProps) => {
	const customizeStyle = {
		background: "#1f262b",
		borderRadius: 16,
		width: props.Width,
		height: props.Height,
		zIndex: 1001,
	};

	const renderMiddleButton = () => {
		return (
			<>
				{!props.SingleButton && props.HasMiddleButton ? (
					<TouchableButton
						style={{ width: "100%", marginBottom: 10 }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorMiddle}
						onPress={props.onPressMiddle}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorMiddle === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{props.ButtonTextMiddle}
							{props.hasSubTextMiddle ? (
								//@ts-ignore
								<StyledSubText>{props.ButtonSubTextMiddle}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderButtons = () => {
		return (
			<>
				{(!props.SingleButton && props.HasFirstButton && props.isTDP) || (!props.SingleButton && !props.isTDP) ? (
					<TouchableButton
						style={{ width: "100%", marginBottom: spacing_m }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorLeft}
						onPress={props.onPressLeft}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorLeft === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{props.ButtonTextLeft}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
				{renderMiddleButton()}
				{props.hasSubTextLeft && props.HasMiddleButton ? (
					//@ts-ignore
					<StyledSubText>{props.ButtonSubTextLeft}</StyledSubText>
				) : (
					<></>
				)}
				{!props.HideButton ? (
					<TouchableButton
						style={{ width: "100%" }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorRight}
						onPress={props.onPressRight}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorRight === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{/**@ts-ignore */}
							<StyledTextLoadingContainer>
								{/**@ts-ignore */}
								<StyledSpinner>
									{props.isProcessing ? (
										<Spinner
											show={true}
											size="small"
											color={props.TypeButtonColorRight === ComponentTypeEnum.Tertiary ? "black" : "white"}
											isStyle={true}
										/>
									) : (
										<></>
									)}
								</StyledSpinner>
								{props.ButtonTextRight}
							</StyledTextLoadingContainer>
							{props.hasSubTextRight ? (
								//@ts-ignore
								<StyledSubText>{props.ButtonSubTextRight}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
			</>
		);
	};

	return (
		<Provider>
			<Portal>
				<StyledModal visible={true} contentContainerStyle={customizeStyle}>
					{/* @ts-ignore */}
					<StyledCloseContent>
						{props.CloseButton && (
							<TouchableOpacity onPress={props.onDismiss}>
								<Image source={{ uri: closeIcon }} width={30} height={30} />
							</TouchableOpacity>
						)}
					</StyledCloseContent>
					{/* @ts-ignore */}
					<StyledInnerContent>
						{/* @ts-ignore */}
						<StyledTextContainer>
							{/* @ts-ignore */}
							<StyledMargin>
								{/* @ts-ignore */}
								<H3>{props.Header}</H3>
							</StyledMargin>
							{props.Body ? (
								//@ts-ignore
								<StyledMargin>
									{/* @ts-ignore */}
									{props.SingleButton ? <H4>{props.Body}</H4> : <H5>{props.Body}</H5>}
								</StyledMargin>
							) : (
								<></>
							)}
							{props.Footer && (
								// @ts-ignore
								<StyledMargin>
									<H6>{parse(props.Footer)}</H6>
								</StyledMargin>
							)}
						</StyledTextContainer>

						{renderButtons()}
					</StyledInnerContent>
					{/* @ts-ignore */}
					<StyledBottomContainer>
						{props.BottomTextLeft ? (
							// @ts-ignore
							<StyledBottomInnerContainer>
								{/* @ts-ignore */}
								<StyledBottomText>{props.BottomTextLeft}</StyledBottomText>
								{/* @ts-ignore */}
								<StyledBottomText>{props.BottomTextRight}</StyledBottomText>
							</StyledBottomInnerContainer>
						) : (
							<></>
						)}
					</StyledBottomContainer>
					{(props.isLegalTerm && !props.HasMiddleButton) || (props.isLegalTerm && props.HasFirstButton) ? (
						<TouchableOpacity onPress={props.onPressTermsAndService}>
							{/* @ts-ignore */}
							<StyledLegal>
								{/* @ts-ignore */}
								<StyledLegalText>{props.legalTextTitle}</StyledLegalText>
								
									<StyledLegalText underline={true}>{props.legalButtonTextTitle}</StyledLegalText>
							</StyledLegal>
						</TouchableOpacity>
					) : (
						<></>
					)}
				</StyledModal>
			</Portal>
		</Provider>
	);
};

export default CustomizeDialogBox;
